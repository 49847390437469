.management {
  .management-banner {
    background-image: url(../../images/home-banner.jpg);
    background-size: cover;
    width: auto;
    background-repeat: no-repeat;
    background-position: center;
    height: 300px;

    @media (max-width: $breakpoints-lg) {
      height: 210px;
    }
  }

  .management-banner-text {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: white;

    @media (max-width: $breakpoints-sm) {
      font-size: 30px;
    }
  }

  .management-grid {
    padding: 10px 15%;

    @media (max-width: $breakpoints-lg) {
      padding: 10px 100px;
    }

    @media (max-width: $breakpoints-sm) {
      padding: 10px 30px;
    }

  }

  hr {
    width: 100%;
    background-color: $secondary;
    height: 3px;
    opacity: 1;
    margin: auto;
  }

  .test {
    display: flex;
  }
}