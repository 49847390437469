.services {
  .services-banner {
    background-image: url(../../images/home-banner.jpg);
    background-size: cover;
    width: auto;
    background-repeat: no-repeat;
    background-position: center;
    height: 300px;

    @media (max-width: $breakpoints-lg) {
      height: 210px;
    }
  }

  .services-banner-text {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;

    @media (max-width: $breakpoints-sm) {
      font-size: 30px;
    }
  }

  .services-intro {
    padding: 100px 15%;

    @media (max-width: $breakpoints-lg) {
      padding: 50px 100px;
    }

    @media (max-width: $breakpoints-sm) {
      padding: 50px 30px;
    }
  }

  .card {
    height: 10rem;
    width: 20rem;
    margin: auto;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-radius: 0;
    border-color: $secondary;
    border-width: 3px;
    display: center;
    position: center;
    align-items: center;
    text-align: center;
  }


  .card-title {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media (max-width: $breakpoints-lg) {
      font-size: 20px;
    }

    @media (max-width: $breakpoints-sm) {
      font-size: 15px;
    }
  }

  .services-grid {
    background-color: $tertiary;
    padding: 5%;
  }

  .services-grid-row {
    display: flex;
    flex-wrap: wrap;
    padding: 2%;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: center;

  }

  .services-grid-block {
    padding: 20px 30px;
    margin: 20px 30px;
    // position: center;
    // display: center;
    // text-align: center;
    // justify-content: center;
    // align-items: center;
  }
}