.footer {
  background-color: $primary;
  height: 300px;
  text-align: center;
  color: white;
  width: contain;

  .footer-logo {
    padding: 30px;
  }

  .footer-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: center;
    width: contain;

  }

  a {
    color: white;
    text-decoration: none;
    padding: 0px 10px;
  }

  a:hover {
    color: $secondary;
  }

  hr {
    width: 100%;
    background-color: $secondary;
    height: 3px;
    opacity: 1;
    margin-top: 30px;
  }
}