body {
  background-color: #fff;
  font-family: 'Montserrat', sans-serif;
}

p {
  margin-bottom: 20px;
  line-height: 1.6;
  font-family: 'Times New Roman', Times, serif;
  color: rgb(85, 85, 85)
}

h1 {
  @include heading
}

h2 {
  @include subhead
}

h3 {
  @include content
}

h4 {
  @include content2
}

section {
  padding: 0px 7rem;

  @media (max-width: $breakpoints-lg) {
    padding: 2rem;
  }
}