.contact {
  .contact-banner {
    background-image: url(../../images/home-banner.jpg);
    background-size: cover;
    width: auto;
    background-repeat: no-repeat;
    background-position: center;
    height: 300px;

    @media (max-width: $breakpoints-lg) {
      height: 210px;
    }
  }

  .contact-banner-text {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;

    @media (max-width: $breakpoints-sm) {
      font-size: 30px;
    }
  }

  .contact-detail {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: center;
    padding: 100px 15%;

    @media (max-width: $breakpoints-lg) {
      padding: 50px 100px;
    }

    @media (max-width: $breakpoints-sm) {
      padding: 50px 30px;
    }
  }

  .contact-detail-title {
    @media (max-width: $breakpoints-sm) {
      font-size: 30px;
    }
  }

  .contact-text {
    max-width: 100%;
  }


  .contact-form {
    padding-left: 7%;
  }

  .card {
    padding: 8%;
    max-width: 390px;
    box-shadow: 3px 1px 5px 0px #9E9E9E;
  }


  p {
    line-height: 1.5;
  }

  ul {
    margin-left: -10px;
    line-height: 1.5;
  }

  img {
    padding-bottom: 10%;
    // max-width: 100%;
    // size: cover;
    width: 100%;
  }
}