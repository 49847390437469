#nav-dropdown {
  color: white;
}

nav {
  background-color: $primary;
  color: white;
  display: flex;
  align-items: center;

  .nav-logo {
    padding-left: 80px;
    padding-right: 120px;

    @media (max-width: $breakpoints-lg) {
      padding: 0px 0px;
    }

    // @media (max-width: $breakpoints-xl) {
    //   padding: 0px 0px;
    // }
  }

  .dropdown-menu {
    margin-left: -337%;
  }

  a.nav-link {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: white;
    font-family: MontserratRegular;
  }

  .nav-link:hover {
    color: $secondary;
  }

  .nav-position {
    display: flex;
    text-align: left;
    align-items: flex-start;
  }

  .nav-column {
    width: 300px;
  }

  .dropdown-item {
    padding: 10px;
  }

  a.dropdown-item {
    font-family: MontserratRegular;
  }
}

button:hover {
  box-shadow: none !important;
  border: none;
  background-color: $primary;
  color: $secondary;
  font-family: MontserratRegular;
}

button {
  box-shadow: none;
  border: none;
  background-color: $primary;
  color: white;
  font-family: MontserratRegular;
  padding-top: 6px;
  padding-bottom: 6px;

}

.navbar-collapse {
  text-align: center;
}