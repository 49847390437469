@font-face {
  font-family: MontserratThin;
  src: local(MontserratThin),
    url(../../fonts/Montserrat-Thin.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: MontserratBold;
  src: local(MontserratBold),
    url(../../fonts/Montserrat-Bold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: MontserratRegular;
  src: local(MontserratRegular),
    url(../../fonts/Montserrat-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@mixin heading {
  font-family: MontserratBold;
  font-size: 45px;
  font-weight: bold;
}

@mixin subhead {
  font-family: MontserratBold;
  font-size: 28px;
  font-weight: 600;
}

@mixin content {
  font-family: MontserratBold;
  font-size: 24px;
  line-height: 1.5;
}

@mixin content2 {
  font-family: MontserratRegular;
  font-size: 24px;
}